import request from 'utils/request';
const api = {
  SIMPLE_STATISTICS_PAGE: '/api/admin/postback/statistics/page',
  POSTBACK_PAGE: '/api/admin/postback/page',
};

// simple/statistics
export function fetchSimpleStatisticsPage(query) {
  return request({
    url: api.SIMPLE_STATISTICS_PAGE,
    method: 'get',
    params: query,
  });
}

// postback
export function fetchPostbackPage(query) {
  return request({
    url: api.POSTBACK_PAGE,
    method: 'get',
    params: query,
  });
}
